import React, { useRef } from 'react';
import { MenuItem, Menu, Divider } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import { saveAs } from 'file-saver';
import PrintableSharePage from '../Common/PrintableSharePage';

interface QRCodeActionsProps {
  url: string;
  title: string;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

const QRCodeActions: React.FC<QRCodeActionsProps> = ({ url, title, anchorEl, onClose }) => {
  const printableContentRef = useRef<HTMLDivElement>(null);

  const formatTitleForFilename = (title: string) => {
    return title.replace(/[^a-zA-Z0-9-_]/g, '_').replace(/\s+/g, '_');
  };

  const handleDownloadQRCode = () => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const qrElement = printableContentRef.current?.querySelector('canvas');
    if (context && qrElement) {
      canvas.width = 500;
      canvas.height = 500;
      context.drawImage(qrElement, 0, 0, qrElement.width, qrElement.height, 0, 0, 500, 500);
      canvas.toBlob((blob) => {
        if (blob) {
          saveAs(blob, `${formatTitleForFilename(title)}_QRCode.png`);
        }
      });
    }
    onClose();
  };

  const handlePrint = useReactToPrint({
    content: () => printableContentRef.current,
  });

  const handleNativeShare = async () => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const qrElement = printableContentRef.current?.querySelector('canvas');
    if (context && qrElement) {
      canvas.width = 500;
      canvas.height = 500;
      context.drawImage(qrElement, 0, 0, qrElement.width, qrElement.height, 0, 0, 500, 500);
      canvas.toBlob(async (blob) => {
        if (blob) {
          const file = new File([blob], `${formatTitleForFilename(title)}_QRCode.png`, { type: 'image/png' });
          try {
            await navigator.share({
              files: [file],
              title: title,
              text: 'Here is the QR code',
            });
          } catch (error) {
            console.error('Error sharing', error);
          }
        }
      });
    }
    onClose();
  };

  const handleShareLink = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: title,
          text: 'Check out this gallery',
          url: url,
        });
      } catch (error) {
        console.error('Error sharing', error);
        await copyLinkToClipboard();
      }
    } else {
      await copyLinkToClipboard();
    }
    onClose();
  };

  const copyLinkToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(url);
      alert('Link copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy link: ', err);
    }
  };

  const isNativeShareSupported = () => {
    return !!navigator.share;
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        MenuListProps={{ dense: true }}
      >
        {isNativeShareSupported() ? (
          <MenuItem onClick={handleNativeShare}>Share QR Code</MenuItem>
        ) : (
          <MenuItem onClick={handleDownloadQRCode}>Download QR Code</MenuItem>
        )}
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handlePrint}>Print QR Code</MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handleShareLink}>
          {isNativeShareSupported() ? 'Share Link' : 'Copy Link'}
        </MenuItem>
      </Menu>

      {/* Hidden Printable Content */}
      <div style={{ display: 'none' }}>
        <PrintableSharePage ref={printableContentRef} url={url} />
      </div>
    </>
  );
};

export default QRCodeActions;