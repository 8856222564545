import { createSelector } from "reselect";
import { RootState } from "../app/store";

const getGalleryState = (state: RootState) => state.galleries;

export const getGalleryNameById = (state: RootState, galleryId: string) =>
  state.galleries.galleries.find(gallery => gallery.id === galleryId)?.name;

export const selectGalleryById = (state: RootState, galleryId: string) =>
  state.galleries.galleries.find(gallery => gallery.id === galleryId);

export const selectIsLoading = createSelector(getGalleryState, (galleries) => galleries.status === 'loading');

export const selectGalleries = createSelector(getGalleryState, (galleries) => galleries.galleries)

export const selectGalleryPreviews = (state: RootState, galleryId: string) =>
  state.galleries.previews[galleryId]?.previews || [];

export const selectGalleryPreviewsLoading = (state: RootState, galleryId: string) =>
  state.galleries.previews[galleryId]?.loading || false;

export const selectGalleryPreviewsError = (state: RootState, galleryId: string) =>
  state.galleries.previews[galleryId]?.error || null;