import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from '../../axiosConfig';
import { generateUserIdentifier } from '../../utils/UserIdentifierGenerator';

interface Album {
  id: string;
  name: string;
  galleryId: string;
  createdAt: string;
  updatedAt: string;
  isOwner: boolean;
}

interface PreviewImage {
  url: string;
  type: 'image' | 'video';
}

interface AlbumPreviews {
  [albumId: string]: {
    previews: PreviewImage[];
    loading: boolean;
    error: string | null;
  };
}

// Update your AlbumState interface
interface AlbumState {
  albums: Album[];
  mediaFiles: string[];
  nextContinuationToken: string | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null | undefined;
  downloadLinks: { name: string; url: string }[];
  previews: AlbumPreviews; // Add this new field
}

const initialState: AlbumState = {
  albums: [],
  mediaFiles: [],
  nextContinuationToken: null,
  status: 'idle',
  error: null,
  downloadLinks: [],
  previews: {}, // Add this new field
};

export const fetchAlbumPreviews = createAsyncThunk(
  'albums/fetchPreviews',
  async ({ galleryId, albumId }: { galleryId: string; albumId: string }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/album/previews/${galleryId}/${albumId}`);
      return { albumId, previews: response.data.previews };
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchAlbums = createAsyncThunk(
  'albums/fetchAlbums',
  async (galleryId: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/album/fetch/${galleryId}`);
      return response.data; // Assuming the server returns an array of albums
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const fetchAlbumById = createAsyncThunk(
  'albums/fetchAlbumById',
  async ({ albumId }: { albumId: string }, { getState, rejectWithValue }) => {
    try {
      const state: any = getState();
      const token = state.auth.token;

      const headers: any = {};
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }

      const response = await axios.get(`/api/album/single/${albumId}`, { headers });
      return response.data;
    } catch (err: any) {
      // Check if the error is a 404
      if (err.response?.status === 404) {
        return rejectWithValue({ message: err.response?.data.message });
      }
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const createAlbum = createAsyncThunk(
  'albums/createAlbum',
  async ({ name, galleryId }: { name: string; galleryId: string }, { getState, rejectWithValue }) => {
    try {
      const state: any = getState();
      const token = state.auth.token;

      const response = await axios.post('/api/album/new', { name, galleryId }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

// Thunk to update an album
export const updateAlbum = createAsyncThunk(
  'albums/updateAlbum',
  async ({ albumId, updateData }: { albumId: string; updateData: Partial<Album> }, { getState, rejectWithValue }) => {
    try {
      const state: any = getState();
      const token = state.auth.token;

      const response = await axios.put(`/api/album/update/${albumId}`, updateData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data; // Assuming the server returns the updated album object
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const fetchAlbumMedia = createAsyncThunk(
  'albums/fetchAlbumMedia',
  async ({ galleryId, albumId, maxKeys = 10, continuationToken }: { galleryId: string; albumId: string; maxKeys?: number; continuationToken?: string }, { getState, rejectWithValue }) => {
    try {
      const state: any = getState();
      const token = state.auth.token;

      const headers: any = {};
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }

      const requestBody: any = {};
      if (!token) {
        requestBody.userIdentifierBody = generateUserIdentifier();
      }

      const response = await axios.post(
        `/api/media/fetch-album-files/${galleryId}/${albumId}`,
        requestBody,
        {
          headers,
          params: { maxKeys, continuationToken }
        }
      );
      return {
        files: response.data.files,
        nextContinuationToken: response.data.nextContinuationToken
      };
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const deleteAlbumById = createAsyncThunk(
  'albums/deleteAlbumById',
  async ({ galleryId, albumId }: { galleryId: string; albumId: string }, { getState, rejectWithValue }) => {
    try {
      const state: any = getState();
      const token = state.auth.token;

      const headers: any = {};
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }

      const response = await axios.delete(`/api/album/delete/${albumId}`, {
        headers,
        data: { galleryId },
      });

      return { albumId, data: response.data }; // Include the response data
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const deleteAlbumMedia = createAsyncThunk(
  'albums/deleteAlbumMedia',
  async (
    { galleryId, keys }: { galleryId: string; keys: string[] },
    { getState, rejectWithValue }
  ) => {
    try {
      const state: any = getState();
      const token = state.auth.token;

      const headers: any = {};
      const requestBody: any = {
        galleryId,
        keys,
      };

      if (token) {
        headers.Authorization = `Bearer ${token}`;
      } else {
        const userIdentifierBody = generateUserIdentifier();
        if (userIdentifierBody) {
          requestBody.userIdentifierBody = userIdentifierBody;
        }
      }

      const response = await axios.post('/api/media/delete-album-files', requestBody, { headers });

      return { deletedKeys: keys, response: response.data };
    } catch (err: any) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const uploadFilesToAlbum = createAsyncThunk(
  'albums/uploadFilesToAlbum',
  async (
    {
      galleryId,
      albumId,
      files,
      onProgress,
    }: {
      galleryId: string;
      albumId: string;
      files: File[];
      onProgress: (index: number, progress: number) => void;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      const state: any = getState();
      const token = state.auth.token;

      const headers: any = {
        'Content-Type': 'application/json',
      };

      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }

      const MAX_FILE_SIZE = 500 * 1024 * 1024; // 500 MB in bytes
      const acceptedFiles: File[] = [];
      const rejectedFiles: { name: string; reason: string }[] = [];

      files.forEach(file => {
        if (file.size > MAX_FILE_SIZE) {
          rejectedFiles.push({ name: file.name, reason: 'File size exceeds 500 MB limit' });
        } else {
          acceptedFiles.push(file);
        }
      });

      const requestBody: any = {
        galleryId,
        albumId,
        files: acceptedFiles.map((file) => ({
          name: file.name,
          type: file.type,
          size: file.size,
        })),
      };

      if (!token) {
        requestBody.userIdentifierBody = generateUserIdentifier();
      }

      console.log('Sending request to generate presigned URLs:', JSON.stringify(requestBody, null, 2));

      // Get presigned URLs
      const response = await axios.post(
        `/api/media/generate-presigned-urls`,
        requestBody,
        { headers }
      );
      console.log('Received response for presigned URLs:', response.data);

      const { urls, rejectedFiles: serverRejectedFiles } = response.data;

      if (serverRejectedFiles && serverRejectedFiles.length > 0) {
        rejectedFiles.push(...serverRejectedFiles.map((name: string) => ({ name, reason: 'Rejected by server' })));
      }

      if (!urls || urls.length === 0) {
        throw new Error('No presigned URLs received from the server');
      }

      // Upload files to presigned URLs
      const uploadPromises = urls.map(async (url: string, index: number) => {
        const file = acceptedFiles[index];
        if (!file) return null;

        try {
          console.log(`Uploading file ${file.name} (size: ${file.size} bytes) to ${url}`);
          await axios.put(url, file, {
            headers: {
              'Content-Type': file.type,
            },
            onUploadProgress: (progressEvent: any) => {
              const progress = (progressEvent.loaded / progressEvent.total) * 100;
              onProgress(index, progress);
            },
          });
          console.log(`Successfully uploaded file ${file.name}`);
          return file.name; // Return the file name if upload is successful
        } catch (error) {
          console.error(`Error uploading file ${file.name}:`, error);
          rejectedFiles.push({ name: file.name, reason: 'Upload failed' });
          return null;
        }
      });

      const uploadResults = await Promise.all(uploadPromises);
      const successfulUploads = uploadResults.filter(Boolean) as string[];

      console.log('Upload results:', { successfulUploads, rejectedFiles });

      return {
        galleryId,
        albumId,
        files: successfulUploads,
        rejectedFiles
      };
    } catch (err: any) {
      console.error('Error in uploadFilesToAlbum:', err);
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

export const downloadAllAlbumMedia = createAsyncThunk(
  'albums/downloadAllAlbumMedia',
  async ({ galleryId, albumId }: { galleryId: string; albumId: string }, { getState, rejectWithValue }) => {
    try {
      const state: any = getState();
      const token = state.auth.token;

      if (!token) {
        return rejectWithValue('You are not authorized to perform this action. Please log in.');
      }

      const response = await axios.get(`/api/media/download-all/${galleryId}/${albumId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data.files;
    } catch (err: any) {
      if (err.response?.status === 401) {
        return rejectWithValue('Your session has expired. Please log in again.');
      }
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

const albumSlice = createSlice({
  name: 'albums',
  initialState,
  reducers: {
    clearAlbums: (state) => {
      state.albums = [];
      state.mediaFiles = [];
      state.nextContinuationToken = null;
    },
    clearDownloadLinks: (state) => {
      state.downloadLinks = [];
    },
    clearPreviews: (state) => {
      state.previews = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAlbums.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAlbums.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.albums = action.payload;
      })
      .addCase(fetchAlbums.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(fetchAlbumById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAlbumById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const existingAlbum = state.albums.find(album => album.id === action.payload.id);
        if (existingAlbum) {
          Object.assign(existingAlbum, action.payload); // Update the existing album
        } else {
          state.albums.push(action.payload); // Add new album if not present
        }
      })
      .addCase(fetchAlbumById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(createAlbum.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createAlbum.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.albums.push(action.payload);
      })
      .addCase(createAlbum.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(fetchAlbumMedia.pending, (state) => {
        state.mediaFiles = [];
        state.status = 'loading';
      })
      .addCase(fetchAlbumMedia.fulfilled, (state, action) => {
        state.mediaFiles = [...state.mediaFiles, ...action.payload.files];
        state.nextContinuationToken = action.payload.nextContinuationToken;
        state.status = 'succeeded';
      })
      .addCase(fetchAlbumMedia.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(uploadFilesToAlbum.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(uploadFilesToAlbum.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(uploadFilesToAlbum.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(deleteAlbumMedia.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteAlbumMedia.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Optionally, remove the deleted media from the mediaFiles array
        state.mediaFiles = state.mediaFiles.filter(
          (file: any) => !action.meta.arg.keys.includes(file.key)
        );
      })
      .addCase(deleteAlbumMedia.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(updateAlbum.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateAlbum.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const existingAlbum = state.albums.find(album => album.id === action.payload.id);
        if (existingAlbum) {
          Object.assign(existingAlbum, action.payload); // Update the existing album with new data
        }
      })
      .addCase(updateAlbum.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(deleteAlbumById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteAlbumById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Use the albumId to filter out the deleted album
        state.albums = state.albums.filter(album => album.id !== action.payload.albumId);

        // Optionally handle additional data returned from the server
        console.log('Delete response:', action.payload.data);
        // You can use this data for further state updates if necessary
      })
      .addCase(deleteAlbumById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(downloadAllAlbumMedia.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(downloadAllAlbumMedia.fulfilled, (state, action: PayloadAction<{ name: string; url: string }[]>) => {
        state.status = 'succeeded';
        state.downloadLinks = action.payload;
      })
      .addCase(downloadAllAlbumMedia.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(fetchAlbumPreviews.pending, (state, action) => {
        const albumId = action.meta.arg.albumId;
        state.previews[albumId] = {
          ...state.previews[albumId],
          loading: true,
          error: null,
        };
      })
      .addCase(fetchAlbumPreviews.fulfilled, (state, action) => {
        const { albumId, previews } = action.payload;
        state.previews[albumId] = {
          previews,
          loading: false,
          error: null,
        };
      })
      .addCase(fetchAlbumPreviews.rejected, (state, action) => {
        const albumId = action.meta.arg.albumId;
        state.previews[albumId] = {
          previews: [],
          loading: false,
          error: action.error.message || 'Failed to fetch previews',
        };
      });
  },
});

export const { clearAlbums, clearDownloadLinks, clearPreviews } = albumSlice.actions;

export default albumSlice.reducer;