// src/components/Gallery/GalleryGrid/GalleryGrid.tsx
import React from 'react';
import GalleryCard, { Gallery } from '../GalleryCard';
import styles from './GalleryGrid.module.css';

interface GalleriesGridProps {
  galleries: Gallery[];
  handleCardClick: (galleryId: string) => void;
  handleDeleteClick?: (event: React.MouseEvent<HTMLElement>, galleryId: string) => void;
  showCreatedAt?: boolean;
  showDelete?: boolean;
  showItemCount?: boolean;
}

const GalleriesGrid: React.FC<GalleriesGridProps> = ({
  galleries,
  handleCardClick,
  handleDeleteClick,
  showCreatedAt = true,
  showDelete = false,
  showItemCount = false,
}) => {
  return (
    <div className={styles.galleriesGrid}>
      {galleries.map((gallery) => (
        <GalleryCard
          key={gallery.id}
          gallery={gallery}
          onCardClick={handleCardClick}
          onDeleteClick={handleDeleteClick}
          showCreatedAt={showCreatedAt}
          showDelete={showDelete}
        />
      ))}
    </div>
  );
};

export default GalleriesGrid;