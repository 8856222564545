import { createSelector } from "reselect";
import { RootState } from "../app/store";

// Base selectors
const getAlbumState = (state: RootState) => state.albums;
const getAlbumPreviews = (state: RootState) => state.albums.previews;
const getRequestedAlbumId = (_state: RootState, albumId: string) => albumId;

// Memoized selectors
export const selectAlbumById = createSelector(
  [getAlbumState, getRequestedAlbumId],
  (albums, albumId) => albums.albums.find(album => album.id === albumId)
);

export const selectIsLoading = createSelector(
  [getAlbumState],
  (albums) => albums.status === 'loading'
);

export const selectAlbums = createSelector(
  [getAlbumState],
  (albums) => albums.albums
);

// Memoized selector for album previews
export const selectAlbumPreviews = createSelector(
  [getAlbumPreviews, getRequestedAlbumId],
  (previews, albumId) => {
    const albumPreviews = previews[albumId];
    return albumPreviews?.previews || [];
  }
);

export const selectAlbumPreviewsLoading = createSelector(
  [getAlbumPreviews, getRequestedAlbumId],
  (previews, albumId) => {
    const albumPreviews = previews[albumId];
    return albumPreviews?.loading || false;
  }
);

export const selectAlbumPreviewsError = createSelector(
  [getAlbumPreviews, getRequestedAlbumId],
  (previews, albumId) => {
    const albumPreviews = previews[albumId];
    return albumPreviews?.error || null;
  }
);

// Memoized selector for album name
export const selectAlbumName = createSelector(
  [selectAlbumById],
  (album) => album?.name
);

// Memoized selector that combines previews with their loading and error states
export const selectAlbumPreviewsWithMeta = createSelector(
  [getAlbumPreviews, getRequestedAlbumId],
  (previews, albumId) => {
    const albumPreviews = previews[albumId] || {
      previews: [],
      loading: false,
      error: null
    };
    return albumPreviews;
  }
);