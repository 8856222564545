import React from 'react';
import { HomeOutlined, LoginOutlined } from '@mui/icons-material';
import { BottomNavigationAction } from '@mui/material';

interface DefaultActionsProps {
  onNavigateHome: () => void;
  onLogout: () => void;
}

const DefaultActions: React.FC<DefaultActionsProps> = ({ onNavigateHome, onLogout }) => {
  return (
    <>
      <BottomNavigationAction
        label="Home"
        icon={<HomeOutlined />}
        onClick={onNavigateHome}
      />
      <BottomNavigationAction
        label="Logout"
        icon={<LoginOutlined />}
        onClick={onLogout}
      />
    </>
  );
};

export default DefaultActions;