// src/components/Common/BottomBar/BottomBar.tsx
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { BottomNavigation } from '@mui/material';
import { AppDispatch, RootState } from '../../../app/store';
import { logout } from '../../../features/auth/authSlice';
import { selectGalleryById } from '../../../selectors/gallerySelectors';
import { selectAlbumById } from '../../../selectors/albumSelectors';
import DashboardActions from './actions/DashboardActions';
import GalleryActions from './actions/GalleryActions';
import AlbumActions from './actions/AlbumActions';
import DefaultActions from './actions/DefaultActions';
import './BottomBar.css';

interface MainBottomBarProps {
  isSelectMode?: boolean;
  onToggleSelectMode?: () => void;
  onDeleteSelected?: () => void;
  onDownloadAll?: () => void;
  selectedPhotosCount?: number;
  onFileDrop?: (acceptedFiles: File[]) => void;
}

const MainBottomBar: React.FC<MainBottomBarProps> = ({
  isSelectMode = false,
  onToggleSelectMode,
  onDeleteSelected,
  onDownloadAll,
  selectedPhotosCount = 0,
  onFileDrop,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState<number>(0);

  // Extract galleryId and albumId from URL if we're on a gallery or album page
  const pathSegments = location.pathname.split('/');
  const galleryId = pathSegments[2];
  const albumId = pathSegments[4];
  const isGalleryPage = location.pathname.startsWith('/galleries/') && !location.pathname.includes('/albums/');
  const isAlbumPage = location.pathname.includes('/albums/');

  // Get gallery and album details if we're on respective pages
  const gallery = useSelector((state: RootState) =>
    isGalleryPage || isAlbumPage ? selectGalleryById(state, galleryId) : null
  );
  const album = useSelector((state: RootState) =>
    isAlbumPage ? selectAlbumById(state, albumId) : null
  );

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  const handleNavigateHome = () => {
    navigate('/dashboard');
  };

  const renderActions = () => {
    if (location.pathname === '/dashboard') {
      return (
        <DashboardActions
          onNavigateHome={handleNavigateHome}
          onLogout={handleLogout}
        />
      );
    }

    if (isGalleryPage && gallery) {
      return (
        <GalleryActions
          galleryId={galleryId}
          galleryName={gallery.name}
          isOwner={gallery.isOwner}
          onNavigateHome={handleNavigateHome}
          onLogout={handleLogout}
        />
      );
    }

    if (isAlbumPage && onToggleSelectMode && onDeleteSelected && onDownloadAll && onFileDrop && album) {
      return (
        <AlbumActions
          isOwner={album.isOwner}
          isSelectMode={isSelectMode}
          onToggleSelectMode={onToggleSelectMode}
          onDeleteSelected={onDeleteSelected}
          onDownloadAll={onDownloadAll}
          selectedPhotosCount={selectedPhotosCount}
          onFileDrop={onFileDrop}
          onNavigateHome={handleNavigateHome}
        />
      );
    }

    return (
      <DefaultActions
        onNavigateHome={handleNavigateHome}
        onLogout={handleLogout}
      />
    );
  };

  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      className="bottom-navigation"
    >
      {renderActions()}
    </BottomNavigation>
  );
};

export default MainBottomBar;