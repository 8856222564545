import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Share as ShareIcon, HomeOutlined, LoginOutlined, AddCircleOutline } from '@mui/icons-material';
import { BottomNavigationAction } from '@mui/material';
import { AppDispatch } from '../../../../app/store';
import { createAlbum } from '../../../../features/album/albumSlice';
import CreateModal from '../../CreateModal';
import QRCodeActions from '../../QRCodeActions';
import DefaultActions from './DefaultActions';

interface GalleryActionsProps {
  galleryId: string;
  galleryName: string;
  isOwner: boolean;
  onNavigateHome: () => void;
  onLogout: () => void;
}

const GalleryActions: React.FC<GalleryActionsProps> = ({
  galleryId,
  galleryName,
  isOwner,
  onNavigateHome,
  onLogout
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [openAlbumModal, setOpenAlbumModal] = useState(false);
  const [shareAnchorEl, setShareAnchorEl] = useState<null | HTMLElement>(null);

  const handleCreateAlbum = (albumName: string, description: string) => {
    dispatch(createAlbum({ name: albumName, galleryId }));
    setOpenAlbumModal(false);
  };

  const handleShareClick = (event: React.MouseEvent<HTMLElement>) => {
    setShareAnchorEl(event.currentTarget);
  };

  const handleShareClose = () => {
    setShareAnchorEl(null);
  };

  if (!isOwner) {
    return <DefaultActions onNavigateHome={onNavigateHome} onLogout={onLogout} />;
  }

  return (
    <>
      <BottomNavigationAction label="Home" icon={<HomeOutlined />} onClick={onNavigateHome} />
      <BottomNavigationAction
        label="New Album"
        icon={<AddCircleOutline />}
        onClick={() => setOpenAlbumModal(true)}
      />
      <BottomNavigationAction
        label="Share"
        icon={<ShareIcon />}
        onClick={handleShareClick}
      />

      <CreateModal
        open={openAlbumModal}
        onClose={() => setOpenAlbumModal(false)}
        onCreate={handleCreateAlbum}
        title="Create New Album"
        label="Album Name"
        type="album"
      />

      <QRCodeActions
        url={window.location.href}
        title={galleryName}
        anchorEl={shareAnchorEl}
        onClose={handleShareClose}
      />
      <BottomNavigationAction label="Logout" icon={<LoginOutlined />} onClick={onLogout} />
    </>
  );
};

export default GalleryActions;