const EmptyState = () => {
  return (
    <svg className="w-full h-full" viewBox="0 0 400 300" preserveAspectRatio="xMidYMid meet">
      {/* Background with grid pattern */}
      <defs>
        <pattern id="grid" width="40" height="40" patternUnits="userSpaceOnUse">
          <path d="M 40 0 L 0 0 0 40" fill="none" stroke="#e0e0e0" strokeWidth="0.5" />
        </pattern>
      </defs>

      {/* Background */}
      <rect width="400" height="300" fill="#f0f0f0" />
      <rect width="400" height="300" fill="url(#grid)" />

      {/* Text label - moved up slightly */}
      <text
        fontFamily="Arial, sans-serif" fontSize="16"
        fill="#757575" textAnchor="middle">
        No images yet
      </text>
    </svg>
  );
};

export default EmptyState;