// src/components/Common/BottomBar/actions/AlbumActions.tsx
import React, { useState } from 'react';
import { BottomNavigationAction } from '@mui/material';
import {
  HomeOutlined,
  CloudUpload,
  CloudDownload,
  SelectAll,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import FileUpload from '../../../Album/FileUpload';
import { Box, Popover } from '@mui/material';

interface AlbumActionsProps {
  isOwner: boolean;
  isSelectMode: boolean;
  onToggleSelectMode: () => void;
  onDeleteSelected: () => void;
  onDownloadAll: () => void;
  selectedPhotosCount: number;
  onFileDrop: (acceptedFiles: File[]) => void;
  onNavigateHome: () => void;
}

const AlbumActions: React.FC<AlbumActionsProps> = ({
  isOwner,
  isSelectMode,
  onToggleSelectMode,
  onDeleteSelected,
  onDownloadAll,
  selectedPhotosCount,
  onFileDrop,
  onNavigateHome,
}) => {
  const [uploadAnchorEl, setUploadAnchorEl] = useState<null | HTMLElement>(null);

  const handleUploadClick = (event: React.MouseEvent<HTMLElement>) => {
    setUploadAnchorEl(event.currentTarget);
  };

  const handleCloseUpload = () => {
    setUploadAnchorEl(null);
  };

  const handleFileDrop = (acceptedFiles: File[]) => {
    onFileDrop(acceptedFiles);
    handleCloseUpload();
  };

  const uploadOpen = Boolean(uploadAnchorEl);

  return (
    <>
      <BottomNavigationAction
        label="Home"
        icon={<HomeOutlined />}
        onClick={onNavigateHome}
      />
      <BottomNavigationAction
        label="Upload"
        icon={<CloudUpload />}
        onClick={handleUploadClick}
      />
      {isOwner && (
        <BottomNavigationAction
          label="Download All"
          icon={<CloudDownload />}
          onClick={onDownloadAll}
        />
      )}
      <BottomNavigationAction
        label={isSelectMode ? "Cancel Select" : "Select"}
        icon={<SelectAll />}
        onClick={onToggleSelectMode}
      />
      {isSelectMode && selectedPhotosCount > 0 && (
        <BottomNavigationAction
          label="Delete"
          icon={<DeleteIcon />}
          onClick={onDeleteSelected}
        />
      )}

      <Popover
        open={uploadOpen}
        anchorEl={uploadAnchorEl}
        onClose={handleCloseUpload}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Box p={2}>
          <FileUpload onDrop={handleFileDrop} />
        </Box>
      </Popover>
    </>
  );
};

export default AlbumActions;