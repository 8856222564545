// src/components/Album/AlbumDetailPage.tsx
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../app/store';
import GenericLayout from '../Common/GenericLayout';
import {
  deleteAlbumMedia,
  fetchAlbumById,
  fetchAlbumMedia,
  updateAlbum,
  uploadFilesToAlbum,
  downloadAllAlbumMedia,
  clearDownloadLinks
} from '../../features/album/albumSlice';
import { getGalleryById } from '../../features/gallery/gallerySlice';
import { selectAlbumById } from '../../selectors/albumSelectors';
import { selectGalleryById } from '../../selectors/gallerySelectors';
import AlbumHeader from './AlbumHeader';
import MainBottomBar from '../Common/BottomBar/BottomBar';
import MediaFilter from './MediaFilter';
import ProgressBar from '../Common/ProgressBar';
import PhotoGallery from './PhotoGallery';
import { Media } from '../../types/media';
import CustomSnackbar from '../Snackbar/Snackbar';
import { downloadAndZipFiles } from '../../utils/downloader';
import DownloadProgressIndicator from './DownloadProgressIndicator';
import VerificationFlow from '../Common/VerificationFlow';
import './AlbumDetailPage.css';

const AlbumDetailPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { galleryId = '', albumId = '' } = useParams<{ galleryId?: string; albumId?: string }>();

  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const [media, setMedia] = useState<Media[]>([]);
  const [totalProgress, setTotalProgress] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadedFilesCount, setUploadedFilesCount] = useState<number>(0);
  const [totalFiles, setTotalFiles] = useState<number>(0);
  const [filter, setFilter] = useState<'all' | 'uploadedByYou'>('all');
  const [isSelectMode, setIsSelectMode] = useState<boolean>(false);
  const [selectedPhotos, setSelectedPhotos] = useState<string[]>([]);
  const initialFetchDone = useRef(false);

  const album = useSelector((state: RootState) => selectAlbumById(state, albumId));
  const gallery = useSelector((state: RootState) => selectGalleryById(state, galleryId));
  const nextContinuationToken = useSelector((state: RootState) => state.albums.nextContinuationToken);
  const isLoading = useSelector((state: RootState) => state.albums.status === 'loading' || isUploading);
  const downloadLinks = useSelector((state: RootState) => state.albums.downloadLinks);

  const [downloadProgress, setDownloadProgress] = useState<number | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'info' | 'warning' | 'error'>('info');
  const [isVerified, setIsVerified] = useState<boolean>(false);

  // Fetch media items handler
  const fetchMediaItems = useCallback((token?: string) => {
    if (!hasMore || isFetching) return;

    setIsFetching(true);
    dispatch(fetchAlbumMedia({ galleryId, albumId, maxKeys: 10, continuationToken: token }))
      .unwrap()
      .then((response) => {
        const formattedMedia = response.files.map((file: any) => ({
          src: file.url,
          key: file.key,
          userIdentifier: file.userIdentifier,
          type: file.type,
          thumbnailUrl: file.thumbnailUrl,
        }));
        setMedia((prevMedia) => [...prevMedia, ...formattedMedia]);
        setHasMore(!!response.nextContinuationToken);
      })
      .catch((error) => {
        console.error('Error fetching media:', error);
        setHasMore(false);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [dispatch, galleryId, albumId, hasMore, isFetching]);

  // File drop handler
  const handleFileDrop = useCallback(
    (acceptedFiles: File[]) => {
      setTotalProgress(0);
      setIsUploading(true);
      setUploadedFilesCount(0);
      setTotalFiles(acceptedFiles.length);

      const progressArray = new Array(acceptedFiles.length).fill(0);
      const hasVideos = acceptedFiles.some(file => file.type.startsWith('video/'));

      const onProgress = (index: number, progress: number) => {
        progressArray[index] = progress;
        const totalProgress = progressArray.reduce((acc, curr) => acc + curr, 0) / acceptedFiles.length;
        setTotalProgress(totalProgress);
        if (progress === 100) {
          setUploadedFilesCount((prevCount) => prevCount + 1);
        }
      };

      dispatch(uploadFilesToAlbum({ galleryId, albumId, files: acceptedFiles, onProgress }))
        .unwrap()
        .then((result) => {
          setIsUploading(false);
          setTotalProgress(0);
          setUploadedFilesCount(0);

          let message = '';
          if (result.files && result.files.length > 0) {
            message += `Successfully uploaded ${result.files.length} file(s). `;
          }

          if (result.rejectedFiles && result.rejectedFiles.length > 0) {
            const rejectedFileNames = result.rejectedFiles.map(f => `${f.name} (${f.reason})`).join(', ');
            message += `The following files were not uploaded: ${rejectedFileNames}`;
            setSnackbarSeverity('warning');
          } else {
            setSnackbarSeverity('success');
          }

          if (hasVideos) {
            message += ' Video files are being optimized for web viewing. This process may take a few minutes.';
            setSnackbarSeverity('info');
          }

          setSnackbarMessage(message);
          setSnackbarOpen(true);

          setMedia([]); // Clear existing media
          setHasMore(true); // Reset hasMore
          fetchMediaItems(); // Fetch all media items again
        })
        .catch((error) => {
          console.error('Error uploading files:', error);
          setIsUploading(false);
          setSnackbarMessage('An error occurred while uploading files.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        });
    },
    [dispatch, galleryId, albumId, fetchMediaItems]
  );

  // Download handler
  const handleDownloadAll = useCallback(() => {
    if (album && album.isOwner) {
      setDownloadProgress(0);
      dispatch(downloadAllAlbumMedia({ galleryId, albumId }))
        .unwrap()
        .catch((error) => {
          console.error('Download failed:', error);
          setSnackbarMessage(error.message || 'Download failed. Please try again.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setDownloadProgress(null);
        });
    }
  }, [dispatch, galleryId, albumId, album]);

  // Delete handler with loading state
  const handleDeleteSelected = useCallback(() => {
    if (selectedPhotos.length > 0) {
      dispatch(deleteAlbumMedia({ galleryId, keys: selectedPhotos }))
        .unwrap()
        .then(() => {
          setSelectedPhotos([]);
          setIsSelectMode(false);
          setSnackbarMessage(`Successfully deleted ${selectedPhotos.length} item(s)`);
          setSnackbarSeverity('success');
          setSnackbarOpen(true);

          setMedia([]); // Clear existing media
          setHasMore(true); // Reset hasMore
          fetchMediaItems(); // Fetch all media items again
        })
        .catch((error) => {
          console.error('Error deleting files:', error);
          setSnackbarMessage('An error occurred while deleting files.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        })
        .finally(() => {
        });
    }
  }, [dispatch, galleryId, selectedPhotos, fetchMediaItems]);

  const handleSelectModeToggle = () => {
    setIsSelectMode(!isSelectMode);
    setSelectedPhotos([]);
  };

  // Other utility handlers
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleTitleChange = (newTitle: string) => {
    if (album) {
      dispatch(updateAlbum({ albumId, updateData: { name: newTitle } }));
    }
  };

  // Effects
  useEffect(() => {
    const checkAccess = () => {
      if (isLoggedIn || (album?.isOwner || gallery?.isOwner)) {
        return true;
      }

      // Check session storage for access verification
      if (gallery?.isPasswordProtected) {
        const galleryAccess = sessionStorage.getItem(`gallery-access-${galleryId}`) === 'true';
        const albumAccess = sessionStorage.getItem(`album-access-${albumId}`) === 'true';
        return galleryAccess && albumAccess;
      }

      // Check local storage for user details
      return Boolean(localStorage.getItem('name') && localStorage.getItem('email'));
    };

    if (gallery && album) {
      setIsVerified(checkAccess());
    }
  }, [gallery, album, galleryId, albumId, isLoggedIn]);

  useEffect(() => {
    dispatch(getGalleryById(galleryId))
      .unwrap()
      .catch(() => navigate('/404'));

    dispatch(fetchAlbumById({ albumId }))
      .unwrap()
      .catch(() => navigate('/404'));
  }, [dispatch, galleryId, albumId, navigate]);

  useEffect(() => {
    if (album && isVerified && !initialFetchDone.current && media.length === 0) {
      initialFetchDone.current = true;
      fetchMediaItems();
    }
  }, [album, isVerified, fetchMediaItems, media.length]);

  useEffect(() => {
    if (downloadLinks.length > 0) {
      downloadAndZipFiles(
        downloadLinks,
        album?.name || 'album',
        (progress) => setDownloadProgress(progress)
      ).then(() => {
        setDownloadProgress(null);
        dispatch(clearDownloadLinks());
      }).catch((error) => {
        console.error('Download failed:', error);
        setSnackbarMessage('Download failed. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setDownloadProgress(null);
        dispatch(clearDownloadLinks());
      });
    }
  }, [downloadLinks, album, dispatch]);

  // Infinite scroll effect
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0
    };

    const observer = new IntersectionObserver((entities) => {
      const target = entities[0];
      if (target.isIntersecting && hasMore && !isFetching && !isLoading) {
        fetchMediaItems(nextContinuationToken || undefined);
      }
    }, options);

    const loader = document.querySelector('#loader');
    if (loader) observer.observe(loader);

    return () => {
      if (loader) observer.unobserve(loader);
    };
  }, [fetchMediaItems, hasMore, isFetching, isLoading, nextContinuationToken]);

  const filteredMedia = media.filter((item) => {
    if (filter === 'all') return true;
    return item.userIdentifier === 'You';
  });

  const handleVerificationComplete = () => {
    setIsVerified(true);
  };

  return (
    <>
      <GenericLayout
        isLoading={isLoading}
        title={album?.name}
        editable={album?.isOwner}
        onTitleChange={handleTitleChange}
      >
        {!isVerified && gallery && album && !album.isOwner && !gallery.isOwner && (
          <VerificationFlow
            galleryId={galleryId}
            albumId={albumId}
            galleryName={gallery.name}
            albumName={album.name}
            isPasswordProtected={gallery.isPasswordProtected}
            onVerificationComplete={handleVerificationComplete}
          />
        )}

        {isVerified && (
          <>
            <AlbumHeader title={album?.name} onTitleChange={handleTitleChange} />

            {media.length > 0 && (
              <MediaFilter filter={filter} onFilterChange={setFilter} />
            )}

            {isUploading && (
              <ProgressBar
                totalProgress={totalProgress}
                uploadedFilesCount={uploadedFilesCount}
                totalFiles={totalFiles}
              />
            )}

            <PhotoGallery
              photos={filteredMedia}
              isOwner={!!album?.isOwner}
              isSelectMode={isSelectMode}
              onPhotoSelect={setSelectedPhotos}
              selectedPhotos={selectedPhotos}
            />

            {hasMore && <div id="loader" style={{ height: "20px" }}></div>}

            {media.length === 0 && !isLoading && !isFetching && (
              <p>No media found in this album.</p>
            )}
          </>
        )}

        {downloadProgress !== null && (
          <DownloadProgressIndicator progress={downloadProgress} />
        )}

        <CustomSnackbar
          open={snackbarOpen}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
      </GenericLayout>

      <MainBottomBar
        isSelectMode={isSelectMode}
        onToggleSelectMode={handleSelectModeToggle}
        onDeleteSelected={handleDeleteSelected}
        onDownloadAll={handleDownloadAll}
        selectedPhotosCount={selectedPhotos.length}
        onFileDrop={handleFileDrop}
      />
    </>
  );
};

export default AlbumDetailPage;