import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../axiosConfig';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
} from '@mui/material';

interface VerificationFlowProps {
  galleryId: string;
  albumId?: string;
  galleryName: string;
  albumName?: string;
  isPasswordProtected: boolean;
  onVerificationComplete: () => void;
}

interface StoredAccessToken {
  token: string;
  expiresAt: string;
}

const VerificationFlow: React.FC<VerificationFlowProps> = ({
  galleryId,
  albumId,
  galleryName,
  albumName,
  isPasswordProtected,
  onVerificationComplete
}) => {
  const [step, setStep] = useState<'pin' | 'details'>('pin');
  const [accessPin, setAccessPin] = useState('');
  const [name, setName] = useState(localStorage.getItem('name') || '');
  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const verifyAccess = useCallback(async () => {
    try {
      setError('');
      setIsSubmitting(true);

      const response = await axios.post('/api/galleries/verify-access', {
        galleryId,
        accessPin: isPasswordProtected ? accessPin.toUpperCase() : undefined,
        guestName: name,
        guestEmail: email
      });

      if (response.data.verified && response.data.accessToken) {
        localStorage.setItem('name', name);
        localStorage.setItem('email', email);

        const tokenData: StoredAccessToken = {
          token: response.data.accessToken,
          expiresAt: response.data.expiresAt
        };

        sessionStorage.setItem(
          `gallery-access-${galleryId}`,
          JSON.stringify(tokenData)
        );

        if (albumId) {
          sessionStorage.setItem(
            `album-access-${albumId}`,
            JSON.stringify(tokenData)
          );
        }

        onVerificationComplete();
      }
    } catch (error: any) {
      setError(error.response?.data?.message || 'Verification failed');
    } finally {
      setIsSubmitting(false);
    }
  }, [galleryId, albumId, isPasswordProtected, accessPin, name, email, onVerificationComplete]);

  useEffect(() => {
    const checkExistingVerification = async () => {
      const storedGalleryAccess = sessionStorage.getItem(`gallery-access-${galleryId}`);
      const storedAlbumAccess = albumId ?
        sessionStorage.getItem(`album-access-${albumId}`) : null;

      if (storedGalleryAccess) {
        try {
          const { token } = JSON.parse(storedGalleryAccess);

          const response = await axios.post('/api/galleries/verify-token', {
            galleryId,
            token
          });

          if (response.data.verified) {
            const tokenData: StoredAccessToken = {
              token,
              expiresAt: response.data.expiresAt
            };
            sessionStorage.setItem(
              `gallery-access-${galleryId}`,
              JSON.stringify(tokenData)
            );

            if (albumId && storedAlbumAccess) {
              sessionStorage.setItem(
                `album-access-${albumId}`,
                JSON.stringify(tokenData)
              );
            }

            onVerificationComplete();
            return;
          } else {
            sessionStorage.removeItem(`gallery-access-${galleryId}`);
            if (albumId) {
              sessionStorage.removeItem(`album-access-${albumId}`);
            }
          }
        } catch (error) {
          console.error('Error verifying token:', error);
          sessionStorage.removeItem(`gallery-access-${galleryId}`);
          if (albumId) {
            sessionStorage.removeItem(`album-access-${albumId}`);
          }
        }
      }

      if (!isPasswordProtected && localStorage.getItem('name') && localStorage.getItem('email')) {
        verifyAccess();
      } else if (!isPasswordProtected) {
        setStep('details');
      }
    };

    checkExistingVerification();
  }, [galleryId, albumId, isPasswordProtected, onVerificationComplete, verifyAccess]);

  const handlePinSubmit = () => {
    if (isPasswordProtected && accessPin.length !== 6) {
      setError('Please enter a valid 6-character pin');
      return;
    }
    setStep('details');
  };

  const handleDetailsSubmit = () => {
    if (!name.trim() || !email.trim()) {
      setError('Please enter both name and email');
      return;
    }
    verifyAccess();
  };

  return (
    <Dialog open fullWidth maxWidth="xs">
      <DialogTitle>
        {step === 'pin' ? 'Access Required' : `Welcome to ${albumName || galleryName}`}
      </DialogTitle>
      <DialogContent>
        {step === 'pin' ? (
          <>
            <Typography variant="body1" gutterBottom>
              This {albumId ? 'album' : 'gallery'} is password protected.
              Please enter the access pin to continue.
            </Typography>
            <TextField
              autoFocus
              fullWidth
              label="Access Pin"
              value={accessPin}
              onChange={(e) => setAccessPin(e.target.value.toUpperCase())}
              error={!!error}
              helperText={error}
              inputProps={{ maxLength: 6 }}
            />
          </>
        ) : (
          <>
            <Typography variant="body1" gutterBottom>
              Please enter your details to continue.
            </Typography>
            <TextField
              autoFocus
              fullWidth
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
              error={!!error}
              helperText={error}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={step === 'pin' ? handlePinSubmit : handleDetailsSubmit}
          variant="contained"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Verifying...' : step === 'pin' ? 'Next' : 'Continue'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VerificationFlow;