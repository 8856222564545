import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowBack, Edit as EditIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import './TopBar.css';
import CreateModal from '../Common/CreateModal';

interface TopBarProps {
  title?: string;
  description?: string;
  backButton?: boolean;
  actions?: React.ReactNode;
  editable?: boolean;
  onTitleChange?: (newTitle: string, newDescription: string, isPasswordProtected: boolean) => void;
  initialPasswordProtection?: boolean;
  accessPin?: string;
}

const TopBar: React.FC<TopBarProps> = ({
  title,
  description,
  backButton = true,
  actions,
  editable = false,
  onTitleChange,
  initialPasswordProtection = false,
  accessPin
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [currentTitle, setCurrentTitle] = useState(title || '');
  const [currentDescription, setCurrentDescription] = useState(description || '');
  const [isPasswordProtected, setIsPasswordProtected] = useState(initialPasswordProtection);

  // Determine if we're on a gallery or album page
  const isGalleryPage = location.pathname.includes('/galleries/') && !location.pathname.includes('/albums/');

  // Extract galleryId from URL if we're on a gallery page
  const galleryId = isGalleryPage
    ? location.pathname.split('/galleries/')[1]?.split('/')[0]
    : '';

  useEffect(() => {
    if (title) {
      setCurrentTitle(title);
    }
    if (description !== undefined) {
      setCurrentDescription(description);
    }
    setIsPasswordProtected(initialPasswordProtection);
  }, [title, description, initialPasswordProtection]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleUpdate = (newTitle: string, newDescription: string, newPasswordProtected: boolean) => {
    if (onTitleChange) {
      setCurrentTitle(newTitle);
      setCurrentDescription(newDescription);
      setIsPasswordProtected(newPasswordProtected);
      onTitleChange(newTitle, newDescription, newPasswordProtected);
    }
    setOpenModal(false);
  };

  return (
    <header className="top-bar">
      {backButton && (
        <span onClick={handleBack} style={{ color: 'black', cursor: 'pointer' }}>
          <ArrowBack fontSize='small' className='icon-button' />
        </span>
      )}

      <div className="top-bar-content">
        <div className="top-bar-title">
          {currentTitle}
          {editable && (
            <IconButton onClick={() => setOpenModal(true)} size="small" style={{ marginLeft: 8 }}>
              <EditIcon fontSize="small" />
            </IconButton>
          )}
        </div>
        {currentDescription && (
          <div className="top-bar-description">
            {currentDescription}
          </div>
        )}
      </div>

      {actions && (
        <div className="top-bar-actions">
          {actions}
        </div>
      )}

      <CreateModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onCreate={handleUpdate}
        title={`Edit ${isGalleryPage ? 'Gallery' : 'Album'} Details`}
        label="Title"
        initialValue={currentTitle}
        initialDescription={currentDescription}
        initialPasswordProtection={isPasswordProtected}
        accessPin={accessPin}
        isUpdate={true}
        type={isGalleryPage ? 'gallery' : 'album'}
        galleryId={isGalleryPage ? galleryId : ''}
      />
    </header>
  );
};

export default TopBar