import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../app/store';
import { Dialog, DialogContent, IconButton, Typography, Box, TextField, Switch, Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close as CloseIcon, ArrowForward as ArrowForwardIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import { refreshGalleryPin } from '../../features/gallery/gallerySlice';
import CustomSnackbar from '../Snackbar/Snackbar';

// Styled components
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '16px',
    maxWidth: '500px',
    width: '100%'
  }
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(4),
  paddingTop: theme.spacing(3)
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(2),
  color: theme.palette.grey[500],
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.paper,
    }
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.divider,
  }
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  borderRadius: '12px',
  padding: theme.spacing(1.5, 4),
  textTransform: 'none',
  fontSize: '1rem',
  fontWeight: 600,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: 'none',
  }
}));

const RefreshIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
  marginLeft: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  }
}));

const StyledSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

interface CreateModalProps {
  open: boolean;
  onClose: () => void;
  onCreate: (name: string, description: string, isPasswordProtected: boolean) => void;
  title: string;
  label: string;
  initialValue?: string;
  initialDescription?: string;
  initialPasswordProtection?: boolean;
  accessPin?: string;
  isUpdate?: boolean;
  type?: 'gallery' | 'album';
  galleryId?: string;
}

const CreateModal: React.FC<CreateModalProps> = ({
  open,
  onClose,
  onCreate,
  title,
  label,
  initialValue = '',
  initialDescription = '',
  initialPasswordProtection = false,
  accessPin = '',
  isUpdate = false,
  type = 'gallery',
  galleryId = ''
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [name, setName] = useState<string>(initialValue);
  const [description, setDescription] = useState<string>(initialDescription);
  const [isPasswordProtected, setIsPasswordProtected] = useState<boolean>(initialPasswordProtection);
  const [error, setError] = useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [isRefreshingPin, setIsRefreshingPin] = useState(false);

  useEffect(() => {
    if (open) {
      setName(initialValue);
      setDescription(initialDescription);
      setIsPasswordProtected(initialPasswordProtection);
      setError('');
    }
  }, [open, initialValue, initialDescription, initialPasswordProtection]);

  const handleCreate = () => {
    if (!name.trim()) {
      setError('Name is required');
      return;
    }
    onCreate(name, description, type === 'gallery' ? isPasswordProtected : false);
  };

  const handleRefreshPin = async () => {
    if (!galleryId) return;

    try {
      setIsRefreshingPin(true);
      await dispatch(refreshGalleryPin({ galleryId })).unwrap();
      setSnackbarMessage('Access pin refreshed successfully. All existing access tokens have been revoked.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Failed to refresh access pin. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsRefreshingPin(false);
    }
  };

  return (
    <>
      <StyledDialog
        open={open}
        onClose={onClose}
        aria-labelledby="create-modal-title"
      >
        <CloseButton onClick={onClose} aria-label="close">
          <CloseIcon />
        </CloseButton>

        <StyledDialogContent>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            sx={{ mb: 3, fontWeight: 600 }}
          >
            {title}
          </Typography>

          <StyledTextField
            autoFocus
            fullWidth
            label={label}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setError('');
            }}
            error={!!error}
            helperText={error}
            variant="outlined"
          />

          <StyledTextField
            fullWidth
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            rows={4}
            placeholder="Add a description (optional)"
            variant="outlined"
          />

          {type === 'gallery' && (
            <Box sx={{ mt: 2, mb: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="subtitle1" color="text.primary">
                  Password Protection
                </Typography>
                <StyledSwitch
                  checked={isPasswordProtected}
                  onChange={(e) => setIsPasswordProtected(e.target.checked)}
                />
              </Box>

              {isPasswordProtected && (
                <Box sx={{ mt: 2 }}>
                  {isUpdate ? (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body2" color="text.secondary">
                        Current Access Pin: {accessPin}
                      </Typography>
                      {isRefreshingPin ? (
                        <CircularProgress size={24} sx={{ ml: 1 }} />
                      ) : (
                        <RefreshIconButton
                          onClick={handleRefreshPin}
                          size="small"
                          title="Refresh Pin"
                        >
                          <RefreshIcon fontSize="small" />
                        </RefreshIconButton>
                      )}
                    </Box>
                  ) : (
                    <Typography variant="body2" color="text.secondary">
                      An access pin will be generated automatically when the gallery is created
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <SubmitButton
              variant="contained"
              onClick={handleCreate}
              endIcon={<ArrowForwardIcon />}
            >
              {isUpdate ? 'Update' : 'Create'}
            </SubmitButton>
          </Box>
        </StyledDialogContent>
      </StyledDialog>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default CreateModal;