import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { HomeOutlined, LoginOutlined, AddCircleOutline } from '@mui/icons-material';
import { BottomNavigationAction } from '@mui/material';
import { AppDispatch } from '../../../../app/store';
import { createGallery } from '../../../../features/gallery/gallerySlice';
import CreateModal from '../../CreateModal';

interface DashboardActionsProps {
  onNavigateHome: () => void;
  onLogout: () => void;
}

const DashboardActions: React.FC<DashboardActionsProps> = ({ onNavigateHome, onLogout }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [openModal, setOpenModal] = useState(false);

  const handleCreateGallery = (name: string, description: string, isPasswordProtected: boolean) => {
    dispatch(createGallery({ name, description, isPasswordProtected }));
    setOpenModal(false);
  };

  return (
    <>
      <BottomNavigationAction label="Home" icon={<HomeOutlined />} onClick={onNavigateHome} />
      <BottomNavigationAction
        label="Add Gallery"
        icon={<AddCircleOutline />}
        onClick={() => setOpenModal(true)}
      />
      <BottomNavigationAction label="Logout" icon={<LoginOutlined />} onClick={onLogout} />

      <CreateModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onCreate={handleCreateGallery}
        title="Create New Gallery"
        label="Gallery Name"
        type="gallery"
      />
    </>
  );
};

export default DashboardActions;