import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { formatLocalDateTime } from '../../utils/DateFormat';
import { AppDispatch, RootState } from '../../app/store';
import { fetchAlbumPreviews } from '../../features/album/albumSlice';
import styles from '../Gallery/GalleryGrid/GalleryGrid.module.css';
import { selectAlbumPreviewsWithMeta } from '../../selectors/albumSelectors';
import EmptyState from '../Common/EmptyState';

interface Album {
  id: string;
  name: string;
  createdAt: string;
  galleryId: string;
  itemCount?: number;
}

interface AlbumCardProps {
  album: Album;
  onCardClick: (albumId: string) => void;
  onDeleteClick?: (event: React.MouseEvent<HTMLElement>, albumId: string) => void;
  showCreatedAt?: boolean;
  showDelete?: boolean;
}

interface AlbumGridProps {
  albums: Album[];
  handleCardClick: (albumId: string) => void;
  handleDeleteClick?: (event: React.MouseEvent<HTMLElement>, albumId: string) => void;
  showCreatedAt?: boolean;
  showDelete?: boolean;
  showItemCount?: boolean;
}

const AlbumCard: React.FC<AlbumCardProps> = ({
  album,
  onCardClick,
  onDeleteClick,
  showCreatedAt = true,
  showDelete = false,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { previews, loading, error } = useSelector((state: RootState) =>
    selectAlbumPreviewsWithMeta(state, album.id)
  );

  useEffect(() => {
    dispatch(fetchAlbumPreviews({ galleryId: album.galleryId, albumId: album.id }));
  }, [dispatch, album.galleryId, album.id]);

  return (
    <div
      className={styles.card}
      onClick={() => onCardClick(album.id)}
    >
      <div className={styles.previewGrid}>
        {loading ? (
          <div className={styles.loadingPreview} />
        ) : previews.length > 0 ? (
          previews.slice(0, 4).map((preview, index) => (
            <div
              key={index}
              className={`${styles.previewItem} ${previews.length === 1 ? styles.single :
                previews.length === 2 ? styles.double :
                  ''
                }`}
            >
              <img
                src={preview.url}
                alt={`Album preview ${index + 1}`}
                className={styles.previewImage}
              />
              {preview.type === 'video' && (
                <PlayCircleFilledWhiteIcon className={styles.videoIcon} />
              )}
            </div>
          ))
        ) : (
          <div className={styles.emptyPreview}>
            <EmptyState />
          </div>
        )}

        {showDelete && onDeleteClick && (
          <IconButton
            className={styles.deleteButton}
            onClick={(e) => {
              e.stopPropagation();
              onDeleteClick(e, album.id);
            }}
            size="small"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}
      </div>

      <div className={styles.cardContent}>
        <h3 className={styles.galleryName}>
          {album.name}
        </h3>

        <div className={styles.metadataContainer}>
          {showCreatedAt && (
            <div className={styles.timestamp}>
              <AccessTimeIcon className={styles.timestampIcon} />
              <span>{formatLocalDateTime(album.createdAt)}</span>
            </div>
          )}

          {album.itemCount !== undefined && (
            <span className={styles.itemCount}>
              {album.itemCount} items
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

const AlbumGrid: React.FC<AlbumGridProps> = ({
  albums,
  handleCardClick,
  handleDeleteClick,
  showCreatedAt = true,
  showDelete = false,
  showItemCount = false,
}) => {
  return (
    <div className={styles.galleriesGrid}>
      {albums.map((album) => (
        <AlbumCard
          key={album.id}
          album={album}
          onCardClick={handleCardClick}
          onDeleteClick={handleDeleteClick}
          showCreatedAt={showCreatedAt}
          showDelete={showDelete}
        />
      ))}
    </div>
  );
};

export default AlbumGrid;