import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Box } from '@mui/material';
import { AppDispatch, RootState } from '../../app/store';
import GenericLayout from '../Common/GenericLayout';
import { selectIsLoading, selectGalleryById } from '../../selectors/gallerySelectors';
import { getGalleryById, updateGallery } from '../../features/gallery/gallerySlice';
import { fetchAlbums, clearAlbums, deleteAlbumById } from '../../features/album/albumSlice';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';
import './GalleryDetailPage.css';
import AlbumGrid from '../Album/AlbumGrid';
import VerificationFlow from '../Common/VerificationFlow';

const GalleryDetailPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { galleryId: paramGalleryId } = useParams<{ galleryId?: string }>();
  const galleryId = paramGalleryId || 'default-id';

  const isLoading = useSelector(selectIsLoading);
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const gallerySelector = useSelector((state: RootState) => selectGalleryById(state, galleryId));
  const albums = useSelector((state: RootState) => state.albums.albums);

  const [gallery, setGallery] = useState<any | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [albumIdToDelete, setAlbumIdToDelete] = useState<string | null>(null);
  const [isVerified, setIsVerified] = useState<boolean>(false);

  useEffect(() => {
    const checkAccess = () => {
      if (isLoggedIn || gallery?.isOwner) {
        return true;
      }
      // Check session storage for access verification
      if (gallery?.isPasswordProtected) {
        return sessionStorage.getItem(`gallery-access-${galleryId}`) === 'true';
      }
      // Check local storage for user details
      return Boolean(localStorage.getItem('name') && localStorage.getItem('email'));
    };

    if (gallery) {
      setIsVerified(checkAccess());
    }
  }, [gallery, galleryId, isLoggedIn]);

  useEffect(() => {
    dispatch(clearAlbums());
    dispatch(getGalleryById(galleryId))
      .unwrap()
      .then((gallery) => {
        setGallery(gallery);
      })
      .catch((error) => {
        navigate('/404');
      });
  }, [dispatch, galleryId, navigate]);

  useEffect(() => {
    if (isVerified) {
      dispatch(fetchAlbums(galleryId));
    }
  }, [dispatch, galleryId, isVerified]);

  useEffect(() => {
    setGallery(gallerySelector);
  }, [gallerySelector]);

  const handleTitleChange = (newTitle: string, newDescription: string, isPasswordProtected: boolean) => {
    const updateData = {
      name: newTitle,
      description: newDescription,
      isPasswordProtected
    };
    dispatch(updateGallery({ galleryId, updateData }));
  };

  const handleAlbumClick = (albumId: string) => {
    navigate(`/galleries/${galleryId}/albums/${albumId}`);
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLElement>, albumId: string) => {
    event.stopPropagation();
    setAlbumIdToDelete(albumId);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (albumIdToDelete) {
      await dispatch(deleteAlbumById({ galleryId, albumId: albumIdToDelete }));
      setAlbumIdToDelete(null);
      setOpenDeleteDialog(false);
    }
  };

  const handleVerificationComplete = () => {
    setIsVerified(true);
  };

  return (
    <>
      <GenericLayout
        isLoading={isLoading}
        title={gallery?.name}
        description={gallery?.description}
        editable={gallery?.isOwner}
        onTitleChange={handleTitleChange}
        initialPasswordProtection={gallery?.isPasswordProtected}
        accessPin={gallery?.accessPin}
      >
        {!isVerified && gallery && !gallery.isOwner && (
          <VerificationFlow
            galleryId={galleryId}
            galleryName={gallery.name}
            isPasswordProtected={gallery.isPasswordProtected}
            onVerificationComplete={handleVerificationComplete}
          />
        )}

        {isVerified && (
          <Box mb={2}>
            {albums.length > 0 ? (
              <>
                <Typography variant="h6" mb={2}>
                  Albums
                </Typography>
                <AlbumGrid
                  albums={albums}
                  handleCardClick={handleAlbumClick}
                  handleDeleteClick={handleDeleteClick}
                  showCreatedAt={false}
                  showDelete={gallery?.isOwner}
                />
              </>
            ) : (
              <Typography variant="h6" mb={2}>
                This gallery has no albums
              </Typography>
            )}
          </Box>
        )}
      </GenericLayout>

      <DeleteConfirmationModal
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onConfirm={handleConfirmDelete}
        description="Deleting the album will also delete all photos/videos within it. Are you sure you want to proceed?"
      />
    </>
  );
};

export default GalleryDetailPage;